'use client'

import React from 'react'

import { useAccount } from 'wagmi'
import TabbedBetslip from 'compositions/TabbedBetslip/TabbedBetslip'

import Controls from '../Controls/Controls'
import { DynamicWidget } from '@dynamic-labs/sdk-react-core'


const RightSidebar: React.FC = () => {
  const { address } = useAccount()

  return (
    <>
      <div className="px-6 py-1 sticky top-0 z-20 right-0">
      {Boolean(address) ? (
        <div className="px-6 sticky py-2 top-0 z-20">
          <Controls className="ml-auto" />{" "}
        </div>
      ) : (
        <div className="flex flex-row sticky py-1 end-0 z-20 justify-end ">
          {" "}
          <DynamicWidget />
        </div>
      )}

      </div>
      <div className="bg-bg-l1 border border-grey-10 rounded-r-md -ml-px overflow-auto wd:h-[calc(100vh_-_4.5rem)] no-scrollbar px-2 pt-1">
        <TabbedBetslip />
      </div>
    </>
  )
}

export default RightSidebar
