import React from 'react'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { Logo } from 'components/ui'
import Navbar from './components/Navbar/Navbar'
import Copy from './components/Copy/Copy'
import { GAMBI_TOKEN_ADDRESS } from 'helpers/constants'

const Footer: React.FC = () => {
  const etherscanUrl = `https://etherscan.io/token/${GAMBI_TOKEN_ADDRESS}`
  const helperAddress = '0xE2Dc0a1790E983b3c0b15fA42eb01feA45730388'
  const helperUrl = `https://etherscan.io/token/${helperAddress}`
  const revenueReserveAddress = '0x93b34d3E775BCb010C61B35F4bc3D5A473122457'
  const revenueReserveUrl = `https://etherscan.io/token/${revenueReserveAddress}`
  const dextoolsUrl = 'https://www.dextools.io/app/en/ether/pair-explorer/0xd1efb6c1d2dabd1a671b301cc9d1b33ce7256d07' // Replace with your actual DEXTools URL

  return (
    <footer className="p-4 pb-3">
      <div className="flex mb:flex-col ds:items-center justify-between pb-4 border-b border-b-grey-10">
        <Logo className="h-4 mb:mb-4" />
        <div className="flex items-center">
          <Navbar />
          <a
            href="https://twitter.com/gambifi"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-4"
          >
            <FontAwesomeIcon icon={faTwitter} className="h-5 w-5" />
          </a>
          <a
            href="https://t.me/gambifi"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-4"
          >
            <FontAwesomeIcon icon={faTelegram} className="h-5 w-5" />
          </a>
          <a
            href={dextoolsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-4"
          >
            <Image
              src="/images/icons/dext.svg"
              alt="DEXTools"
              width={20}
              height={20}
            />
          </a>
        </div>
      </div>
      <div className="mt-3 flex flex-col justify-between items-center">
        <Copy />
        <div className="flex flex-col text-xs">
          <div className="text-gray-500">
            $GAMBI Token: {' '}
            <a
              href={etherscanUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="font-mono text-blue-500 hover:text-blue-700 transition-colors"
            >
              {GAMBI_TOKEN_ADDRESS}
            </a>
          </div>
          <div className="text-gray-500">
            $GAMBI Helper Address: {' '}
            <a
              href={helperUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="font-mono text-blue-500 hover:text-blue-700 transition-colors"
            >
              {helperAddress}
            </a>
          </div>
          <div className="text-gray-500">
            $GAMBI Revenue Reserve Address: {' '}
            <a
              href={revenueReserveUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="font-mono text-blue-500 hover:text-blue-700 transition-colors"
            >
              {revenueReserveAddress}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
