"use client";

import React from "react";
import { createConfig, type State } from "wagmi";
import { type ChainId } from "@azuro-org/toolkit";
import { http, type Address } from "viem";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { IntlProvider } from "@locmod/intl";
import { SvgProvider, SvgSprite } from "svg-provider";
import { AzuroSDKProvider, LiveProvider } from "@azuro-org/sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { DeviceProvider, OddsViewProvider } from "contexts";

import NewFreeBetsChecker from "compositions/NewFreeBetsChecker/NewFreeBetsChecker";
import { chiliz, gnosis, polygon, polygonAmoy } from "viem/chains";
import constants from "helpers/constants";

type Props = {
  userAgent: string;
  initialChainId: ChainId;
  initialLiveState: boolean;
  initialState?: State;
};

const config = createConfig({
  chains: [polygon, polygonAmoy, gnosis, chiliz],
  transports: {
    [polygon.id]: http(constants.rpcByChains[polygon.id]),
    [polygonAmoy.id]: http(constants.rpcByChains[polygonAmoy.id]),
    [gnosis.id]: http(constants.rpcByChains[gnosis.id]),
    [chiliz.id]: http(constants.rpcByChains[chiliz.id]),
  },
  multiInjectedProviderDiscovery: false,
  ssr: true,
  pollingInterval: 2000,
});

// Required to update the copy of the connect wallet button
const customLocale = {
  en: {
    dyn_widget: {
      connect: "Join Gambi",
    },
  },
};

const cssOverrides = `
  @media (max-width: 430px) {
    .dynamic-widget-inline-controls__network-picker span {
      display: none; // Condenses the network picker on mobile
    }
    .dynamic-widget-inline-controls__account-control p {
      display: none; // Condenses the account control on mobile
    }
  }
`;

const Providers: React.CFC<Props> = (props) => {
  const {
    children,
    userAgent,
    initialState,
    initialChainId,
    initialLiveState,
  } = props;

  const queryClient = new QueryClient();

  return (
    <DeviceProvider userAgent={userAgent}>
      <SvgProvider>
        <IntlProvider locale="en">
          <WagmiProvider config={config as any}>
            <DynamicContextProvider
              settings={{
                environmentId: "50d96f76-3609-4e7b-bcd5-d493e36dbb65",
                walletConnectors: [
                  EthereumWalletConnectors,
                ],
                cssOverrides: cssOverrides,
              }}
              locale={customLocale} // Passed in custom locale here
            >
              <QueryClientProvider client={queryClient}>
                <AzuroSDKProvider
                  initialChainId={initialChainId}
                  affiliate={
                    "0x57327558C930f3BD19943d2d5BE2cF4a6183e37E" as Address
                  }
                >
                  <DynamicWagmiConnector>
                    <LiveProvider initialLiveState={initialLiveState}>
                      <OddsViewProvider>{children}</OddsViewProvider>
                    </LiveProvider>
                  </DynamicWagmiConnector>
                  <NewFreeBetsChecker />
                </AzuroSDKProvider>
              </QueryClientProvider>
            </DynamicContextProvider>
          </WagmiProvider>
        </IntlProvider>
        <div className="sr-only">
          <SvgSprite />
        </div>
      </SvgProvider>
    </DeviceProvider>
  );
};

export default Providers;
