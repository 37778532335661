'use client'

import React from 'react'

import StarBackground from 'components/ui/StarBackground/StarBackground'
import { GlobalModalsRegistrar } from 'compositions/modals'

import { Content, Footer } from './components'
import { redirect } from 'next/navigation'
import { headers } from 'next/headers'
import { useGeolocation } from 'hooks'


const PageLayout: React.CFC = (props) => {
  let { children } = props

  
  const { country } = useGeolocation()
  const disallowedCountries = ['TR', 'US', 'RU', 'CN']

  if (disallowedCountries.includes(country as string)) {
    redirect('/not_allowed')
  }

  return (
    <>
      <StarBackground />
      <Content>
            <div className="flex-1">{children}</div>
            <Footer />
            <GlobalModalsRegistrar />

      </Content>
      <div id="modals" />
    </>
  )
}

export default PageLayout
