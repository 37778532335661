'use client'

import React, { useState } from 'react'
import { Message } from '@locmod/intl'
import cx from 'classnames'

import Betslip from 'compositions/Betslip/Betslip'
import AcceptedBets from 'compositions/AcceptedBets/AcceptedBets'

import messages from './messages'


const tabs = [ 'betslip', 'myBets' ] as const

const TabbedBetslip: React.FC = () => {
  const [ activeTab, setActiveTab ] = useState<typeof tabs[number]>('betslip')

  return (
    <>
      <div className="bg-bg-l0 rounded-lg border border-grey-15 p-1 flex items-center shadow-md">
        {
          tabs.map(tab => {
            const isActive = activeTab === tab

            return (
              <button
                key={tab}
                className={
                  cx('w-full py-1 px-2 text-sm text-center rounded-md transition-all duration-300 ease-in-out transform hover:scale-105', {
                    'text-grey-60 hover:text-grey-90 hover:bg-grey-5': !isActive,
                    'text-white bg-purple-800 shadow-lg': isActive,
                  })
                }
                onClick={() => setActiveTab(tab)}
              >
                <span className="font-semibold">
                  <Message value={messages[tab]} />
                </span>
              </button>
            )
          })
        }
      </div>
      {
        activeTab === 'betslip' && (
          <Betslip />
        )
      }
      {
        activeTab === 'myBets' && (
          <AcceptedBets />
        )
      }
    </>
  )
}

export default TabbedBetslip
