import { useState, useEffect } from 'react'

export default function useGeolocation() {
  const [country, setCountry] = useState<string | null>(null)

  useEffect(() => {
    async function fetchGeolocation() {
      try {
        const response = await fetch('/api/geo')
        if (response.ok) {
          const data = await response.text()
          const match = data.match(/<h1>Your location is (.+)<\/h1>/)
          if (match) {
            setCountry(match[1])
            
          }
        } else if (response.status === 403) {
          const url = new URL(response.url)
          setCountry(url.searchParams.get('country'))
          console.log(country)
        }
      } catch (error) {
        console.error('Error fetching geolocation:', error)
      }
    }

    fetchGeolocation()
  }, [])

  console.log(country)
  return { country }
}

