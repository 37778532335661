export default {
  balance: {
    en: 'Balance',
  },
  inBets: {
    en: 'In bets',
  },
  toRedeem: {
    en: 'To redeem',
  },
  wrapUnwrapTooltip: {
    en: 'Wrap / Unwrap CHZ',
  },
}
