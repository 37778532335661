"use client"
import React from 'react'


const StarBackground: React.FC = () => {
  return (
    <div className="fixed inset-0 pointer-events-none z-[-1]">
      {
        [ ...Array(25) ].map((_, index) => (
          <img
            key={index}
            src="/images/gambiStar.svg"
            alt="Gambi Star"
            className="absolute animate-twinkle opacity-50"
            style={
              {
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                width: `${Math.random() * 20 + 10}px`,
                animationDelay: `${Math.random() * 5}s`,
              }
            }
          />
        ))
      }
    </div>
  )
}

export default StarBackground
