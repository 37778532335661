'use client'

import React from 'react'
import { Tab } from '@headlessui/react'
import { useLive } from '@azuro-org/sdk'
import cx from 'classnames'

import { Icon } from 'components/ui'


const LiveSwitcher: React.FC<{className?: string}> = ({ className }) => {
  const { isLive, changeLive } = useLive()

  return (
    <div className={cx('border border-accent-red-5 rounded-md bg-live-switcher-bg flex items-center justify-between m-1', className)}>
      <Tab.Group selectedIndex={isLive ? 1 : 0} onChange={(index) => changeLive(index === 1)}>
        <Tab.List className="flex w-full justify-between bg-bg-l1 rounded-sm">
          <Tab className={({ selected }) => cx(
            'px-3 py-1 text-xs font-medium w-full rounded-l-md transition-colors duration-200 ease-in-out',
            selected ? 'bg-accent-green text-grey-10' : 'text-grey-60'
          )}>
            Pre-Match
          </Tab>
          <Tab className={({ selected }) => cx(
            'flex flex-row items-center gap-x-2 justify-center rounded-r-md px-3 py-1 text-xs font-medium  w-full transition-colors duration-200 ease-in-out',
            selected ? 'bg-accent-red text-grey-10' : 'text-grey-60'
          )}>
            In-Play
            <Icon className="size-4 mr-2" name="interface/live" />
          </Tab>
        </Tab.List>
      </Tab.Group>
    </div>
  )
}

export default LiveSwitcher
